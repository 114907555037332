<template>
  <div id="solution">
    <Header now-nav="/solutionList" ref="header" @thisPageType="thisPageType"></Header>

    <!--    智慧化工管廊-->
    <div v-if="thisSolutionName==='智慧化工管廊'">

      <!--  头部区  -->
      <div class="headerBox" >
        <img :src="baseUrl+'imges/solution/solutionlist_01/solutionBG1_1.png'" alt="" class="PC">
        <img :src="baseUrl+'imges/solution/solutionlist_01/MobileSolutionListBG.png'" alt="" class="MobileBG">
        <div class="headerBox_content" data-aos="fade-up" data-aos-duration="1000">
          <div class="content_Box">
            <h4>智慧化工管廊</h4>
            <h4 class="PC">为化工厂/化工园区物流运输保驾护航</h4>
            <h4 class="Mobile">为化工厂/化工园区<br>物流运输保驾护航</h4>
            <ul>
              <li>管廊、管线全流程管理</li>
              <li>应急管理数字化</li>
              <li>异构设备联动一体化</li>
            </ul>
          </div>
        </div>
      </div>

      <!--  内容区  -->
      <div class="navBox">
        <img :src="baseUrl+'imges/solution/solutionlist_01/solutionBG1_2.png'" alt="">
        <div class="navBox_right right_hight">
          <div class="navBox_content">
            <h4 data-aos="fade-up" data-aos-duration="1000">智慧化工管廊</h4>
            <p data-aos="fade-up" data-aos-duration="1000">化工管廊是化工厂及化工园区物料输送大动脉，管线数量大且覆盖面积广。但多年来，企业对管线管廊的管理仍采用传统管理方式。管线基础信息不完整，当前管线运行信息与历史资料差异大；缺乏智能化监测设备实时监控，全靠人工巡检安全隐患不易察觉，且应急处理机制依靠经验或记忆进行，无法保证准确高效，严重阻碍企业高效生产管理。</p>
<!--            <p data-aos="fade-up" data-aos-duration="1000">化工管廊是化工厂及化工园区物料输送大动脉，管线数量大且覆盖面积广。但多年来，企业对管线管廊的管理仍采用传统管理方式。管线基础信息不完整，当前管线运行信息与历史资料差异大；缺乏智能化监测设备实时监控，无法保证准确高效，严重阻碍企业高效生产管理。</p>-->
            <p data-aos="fade-up" data-aos-duration="1000">智慧化工管廊综合管理解决方案是针对目前化工厂/化工园区的管廊管理难点和痛点，经过多年技术沉淀，与应用场景深度融合，利用大数据、工业物联网、5G等先进技术而打造的一套完整性数字化管理方案，助力企业安全管控和数字化管理全面升级。</p>
            <div class="navBox_content_logo" data-aos="fade-up" data-aos-duration="1000">
              <div>
                <img :src="baseUrl+'imges/solution/solutionlist_01/solutionLogo1.png'" alt="">
                <p>完整性管理</p>
              </div>
              <div>
                <img :src="baseUrl+'imges/solution/solutionlist_01/solutionLogo2.png'" alt="" style="margin-top: 6px">
                <p >业务流程管理</p>
              </div>
              <div>
                <img :src="baseUrl+'imges/solution/solutionlist_01/solutionLogo3.svg'" alt="">
                <p>管线全生命周期管理</p>
              </div>
              <div>
                <img :src="baseUrl+'imges/solution/solutionlist_01/solutionLogo4.svg'" alt="">
                <p>商务管理</p>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!--  特点区  -->
      <div class="featrueBox">
        <p data-aos="fade-up" data-aos-duration="1000">核心价值</p>
        <div class="featrueBox_List">
          <div data-aos="fade-up" data-aos-duration="1000">
            <h4>数字可视化</h4>
            <p>运用地理信息（GIS）和三维建模技术，将管廊管线场景实现物理空间到虚拟空间的全景仿真，并实现管廊管线所有信息的完整和结构化的集成。用户可进行管廊全方位可视化查询、定位、监控和管理。</p>
            <img :src="baseUrl+'imges/solution/solutionlist_01/feature3.png'" alt="">
          </div>
          <div data-aos="fade-up" data-aos-duration="1500">
            <h4>管理完整性</h4>
            <p>以管廊数据为核心，通过风险因素识别和风险评估，确定风险等级及分布区域，对高风险部位实施针对性风险感知（如在线监测，精准巡检）和管控（如隐患排查，辅助决策，应急管理等）。</p>
            <img :src="baseUrl+'imges/solution/solutionlist_01/feature2.png'" alt="">
          </div>
          <div data-aos="fade-up" data-aos-duration="2500">
            <h4>智能化运维</h4>
            <p>结合公共管廊现状和日常业务管理需要，建设面向管廊和管线所属单位的综合性管理平台，实现业务线上便捷交互与智能化办理。借助平台可视化数据底座，实现流程的可视化监控及联动。</p>
            <img :src="baseUrl+'imges/solution/solutionlist_01/feature1.png'" alt="">
          </div>
        </div>
      </div>

      <!--  轮播区  -->
      <div class="carouseBox">

        <div class="carouseBox_List">
          <div class="carouseBox_List_content">
            <div class="carouseBox_List__left">
              <div class="left_text">
                <h4>三维管线应用系统</h4>
                <span>运用三维可视化技术和地理信息技术对公共管廊物理实景进行等比例建模； 在可视化模型中，集成管线属性信息、管廊轴剖面图、管线介质信息、单线图、工艺流程图、管线档案等静态信息。</span>
                <p>集成管线运行状态和安全运行监测的实时动态数据，实现公共管廊所有异构设备、数据和系统的互联互通。</p>
              </div>
              <!-- 小轮播图 -->
              <div class="left_carouse">
                <el-carousel trigger="click"
                             height="500px"
                             indicator-position="outside"
                             arrow="always"
                             :autoplay="false"
                             @change="((pre,next,index) => {change(pre, next,1)})">
                  <el-carousel-item v-for="(item, index) in carouseImgList_1" :key="item">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <p>管廊、管线信息查询</p>
              </div>
            </div>
            <div class="carouseBox_List__right" data-aos="fade-left" data-aos-duration="1000">
              <div class="right_carouse">
                <el-carousel
                    trigger="click"
                    height="1000px"
                    indicator-position="outside"
                    arrow="always"
                    :autoplay="false"
                    ref="carousel1">
                  <el-carousel-item v-for="(item, index) in carouseImgList_2" :key="item">
                    <img v-if="index === 0" :src="item" alt="">
                    <img v-else :src="item" alt="" style="width: 80%;">
                  </el-carousel-item>
                </el-carousel>
                <p class="Mobile">管廊、管线信息查询</p>
              </div>
            </div>
          </div>
        </div>

        <div class="carouseBox_List" style="background-color: #fff;">
          <div class="carouseBox_List_content">
            <div class="carouseBox_List__left Mobile">
              <div class="left_text">
                <h4>管廊完整性管理系统</h4>
                <span>以管廊数据为核心，通过风险因素识别、风险评估、风险等级及分布区域划分、对不同风险设置针对性的感知预警系统。</span>
                <p>建立包括应急模拟演练﹑应急预案与资源配置﹑应急响应与辅助决策﹑调度指挥与监控执行等可视化闭环管理管控机制，以达到使管廊长期安全运行的目的。</p>
              </div>
              <!-- 小轮播图 -->
              <div class="left_carouse">
                <el-carousel trigger="click"
                             height="500px"
                             indicator-position="outside"
                             arrow="always"
                             initial-index="1"
                             :autoplay="false"
                             @change="((pre,next,index) => {change(pre, next, 2)})">
                  <el-carousel-item v-for="(item, index) in carouseImgList_1" :key="item">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <p>应急响应</p>
              </div>
            </div>
            <div class="carouseBox_List__right" data-aos="fade-right" data-aos-duration="1000">
              <div class="right_carouse">
                <el-carousel
                    trigger="click"
                    height="1000px"
                    indicator-position="outside"
                    arrow="always"
                    :autoplay="false"
                    ref="carousel2"
                    initial-index="1">
                  <el-carousel-item v-for="(item, index) in carouseImgList_2" :key="item">
                    <img v-if="index === 0" :src="item" alt="">
                    <img v-else :src="item" alt="" style="width: 80%;">
                  </el-carousel-item>
                </el-carousel>
                <p class="Mobile">应急响应</p>
              </div>
            </div>
            <div class="carouseBox_List__left PC">
              <div class="left_text">
                <h4>管廊完整性管理系统</h4>
                <span>以管廊数据为核心，通过风险因素识别、风险评估、风险等级及分布区域划分、对不同风险设置针对性的感知预警系统。</span>
                <p>建立包括应急模拟演练﹑应急预案与资源配置﹑应急响应与辅助决策﹑调度指挥与监控执行等可视化闭环管理管控机制，以达到使管廊长期安全运行的目的。</p>
              </div>
              <!-- 小轮播图 -->
              <div class="left_carouse">
                <el-carousel trigger="click"
                             height="500px"
                             indicator-position="outside"
                             arrow="always"
                             initial-index="1"
                             :autoplay="false"
                             @change="((pre,next,index) => {change(pre, next, 2)})">
                  <el-carousel-item v-for="(item, index) in carouseImgList_1" :key="item">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <p>应急响应</p>
              </div>
            </div>
          </div>
        </div>

        <div class="carouseBox_List">
          <div class="carouseBox_List_content">
            <div class="carouseBox_List__left">
              <div class="left_text">
                <h4>管廊业务流程管理</h4>
                <p>针对公共管廊日常巡检管理、管线入廊及投用管理、管廊施工作业管理等业务，
                  实现全流程线上智能管理，同时借助平台数据底座，实现业务流程的动态协同与可视化监控。</p>
              </div>
              <!-- 小轮播图 -->
              <div class="left_carouse">
                <el-carousel trigger="click" height="151px" indicator-position="outside" arrow="always" initial-index="2" :autoplay="false" @change="((pre,next,index) => {change(pre, next,3)})">
                  <el-carousel-item v-for="(item, index) in carouseImgList_1" :key="item">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <p>巡检管理</p>
              </div>
            </div>
            <div class="carouseBox_List__right">
              <div class="right_carouse" data-aos="fade-left" data-aos-duration="1000">
                <el-carousel
                    trigger="click"
                    height="1000px"
                    indicator-position="outside"
                    arrow="always"
                    :autoplay="false"
                    ref="carousel3"
                    initial-index="2">
                  <el-carousel-item v-for="(item, index) in carouseImgList_2" :key="item">
                    <img v-if="index === 0" :src="item" alt="">
                    <img v-else :src="item" alt="" style="width: 80%;">
                  </el-carousel-item>
                </el-carousel>
                <!--                手机注释-->
                <p class="Mobile">巡检管理</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!--    智慧城市地下管网-->
    <div v-if="thisSolutionName==='智慧城市地下管网'">

      <!--  头部区  -->
      <div class="headerBox" >
        <img :src="baseUrl+'imges/solution/solutionlist_02/solutionBG2_1.png'" alt="">
        <div class="headerBox_content headerBox_BG" data-aos="fade-up" data-aos-duration="1000">
          <div class="content_Box">
            <h4>智慧地下管网</h4>
            <h4 class="PC">让地下城市发展“看得见”</h4>
            <h4 class="Mobile">让地下城市发展<br>“看得见”</h4>
            <ul>
              <li>城市内涝预警，危害预测</li>
              <li>供水管网泄漏监测，应急响应</li>
              <li>地下管网施工模拟，管网分析</li>
            </ul>
          </div>
        </div>
      </div>

      <!--  内容区  -->
      <div class="navBox">
        <img :src="baseUrl+'imges/solution/solutionlist_02/solutionBG2_2.png'" alt="">
        <div class="navBox_right">
          <div class="navBox_content">
            <h4 data-aos="fade-up" data-aos-duration="1000">智慧城市地下综合管网</h4>
            <p data-aos="fade-up" data-aos-duration="1000">随着城市化进程的不断发展，城市地下管理重要性日益明显。但管网延伸距离长，且因其填埋地下，多管道纵横交错位置信息不准确，巡检空间有限，管理难度大，同时管网管理割裂情况严重，不能形成统一管理。</p>
            <p data-aos="fade-up" data-aos-duration="1000">智慧城市地下综合管网解决方案，通过物联网、三维仿真、GIS、大数据技术，整合各个地下管网权属单位的运维力量，对管网的运行维护、安全管理、应急指挥调度进行信息化管理，提升管理能力和效率。</p>
            <div class="navBox_content_logo" data-aos="fade-up" data-aos-duration="1000">
              <div>
                <img :src="baseUrl+'imges/solution/solutionlist_02/yingjiLogo.svg'" alt="">
                <p>应急管理</p>
              </div>
              <div>
                <img :src="baseUrl+'imges/solution/solutionlist_02/shigongLogo.svg'" alt="">
                <p>施工模拟</p>
              </div>
              <div>
                <img :src="baseUrl+'imges/solution/solutionlist_02/solutionLogo3.svg'" alt="">
                <p>管线全生命周期管理</p>
              </div>
              <div>
                <img :src="baseUrl+'imges/solution/solutionlist_02/solutionLogo4.svg'" alt="">
                <p>商务管理</p>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!--  特点区  -->
      <div class="featrueBox">
        <p data-aos="fade-up" data-aos-duration="1000">核心价值</p>
        <div class="featrueBox_List">
          <div data-aos="fade-up" data-aos-duration="1000">
            <h4>施工智能模拟</h4>
            <p>系统具备多维度分析量算功能，切合城市规划设计，满足地下管网施工需求，提供开挖模拟、供电管网分析、断电影响区域评估等。</p>
            <img :src="baseUrl+'imges/solution/solutionlist_02/feature4.png'" alt="" class="imgheight">
          </div>
          <div data-aos="fade-up" data-aos-duration="1500">
            <h4>管网智能监测</h4>
            <p>针对不同类型管网提供燃气泄露超压报警、自来水泄漏报警、排水管网水位/流速/流量监测对自然灾害内涝预警等，提高应急管理能力。</p>
            <img :src="baseUrl+'imges/solution/solutionlist_02/feature5.png'" alt="" class="imgheight">
          </div>
          <div data-aos="fade-up" data-aos-duration="2500">
            <h4>管网数据互联</h4>
            <p>系统可与环保系统、管网权属单位系统、应急系统等对接，为其他系统提供数据支撑，同时可从其他系统获取数据。全面提升数据来源面，为大数据分析提供支撑。</p>
            <img :src="baseUrl+'imges/solution/solutionlist_02/feature6.png'" alt="" class="imgheight">
          </div>
        </div>
      </div>

      <!--  轮播区  -->
      <div class="carouseBox">

        <div class="carouseBox_List">
          <div class="carouseBox_List_content">
            <div class="carouseBox_List__left">
              <div class="left_text">
                <h4>三维可视化</h4>
                <p>三维GIS系统通过三维建模模拟地下环境，可实现水平/垂直测距，开挖分析，联通分析，爆管分析等。为地下管网施工及日常维护提供数据支撑。</p>
              </div>
              <!-- 小轮播图 -->
              <div class="left_carouse">
                <el-carousel trigger="click"
                             height="500px"
                             indicator-position="outside"
                             arrow="always"
                             :autoplay="false"
                             @change="((pre,next,index) => {change(pre, next, 4)})">
                  <el-carousel-item v-for="(item, index) in carouseImgList_3" :key="index">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <p>地下管线统计分析</p>
              </div>
            </div>
            <div class="carouseBox_List__right" data-aos="fade-left" data-aos-duration="1000">
              <div class="right_carouse">
                <el-carousel
                    trigger="click"
                    height="1000px"
                    indicator-position="outside"
                    arrow="always"
                    :autoplay="false"
                    ref="carousel4">
                    <el-carousel-item v-for="(item, index) in carouseImgList_4" :key="index">
                      <img v-if="index === 2" :src="item" alt="" style="width: 80%;">
                      <img v-else :src="item" alt="">
                    </el-carousel-item>
                </el-carousel>
                <p class="Mobile">爆管分析</p>
              </div>
            </div>
          </div>
        </div>

        <div class="carouseBox_List" style="background-color: #fff;">
          <div class="carouseBox_List_content">
            <div class="carouseBox_List__left Mobile">
              <div class="left_text">
                <h4>地下管廊施工管理</h4>
<!--                <span>以管廊数据为核心，通过风险因素识别、风险评估、风险等级及分布区域划分、对不同风险设置针对性的感知预警系统</span>-->
                <p>系统对管网施工工作全流程监管，对工程进度和工程人员进行全面管理，并对工程图纸、合同、文档等统一管理，保障施工工作的质量和安全。</p>
              </div>
              <!-- 小轮播图 -->
              <div class="left_carouse">
                <el-carousel trigger="click"
                             height="500px"
                             indicator-position="outside"
                             arrow="always"
                             :autoplay="false"
                             initial-index="1"
                             @change="((pre,next,index) => {change(pre, next, 5)})">
                  <el-carousel-item v-for="(item, index) in carouseImgList_3" :key="index">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <p>施工管理</p>
              </div>
            </div>
            <div class="carouseBox_List__right" data-aos="fade-right" data-aos-duration="1000">
              <div class="right_carouse">
                <el-carousel
                    trigger="click"
                    height="1000px"
                    indicator-position="outside"
                    arrow="always"
                    :autoplay="false"
                    initial-index="1"
                    ref="carousel5">
                  <el-carousel-item v-for="(item, index) in carouseImgList_4" :key="index">
                    <img v-if="index === 2" :src="item" alt="" style="width: 80%;">
                    <img v-else :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <p class="Mobile">施工管理</p>
              </div>
            </div>
            <div class="carouseBox_List__left PC">
              <div class="left_text">
                <h4>管廊完整性管理系统</h4>
<!--                <span>以管廊数据为核心，通过风险因素识别、风险评估、风险等级及分布区域划分、对不同风险设置针对性的感知预警系统</span>-->
                <p>系统对管网施工工作全流程监管，对工程进度和工程人员进行全面管理，并对工程图纸、合同、文档等统一管理，保障施工工作的质量和安全。</p>
              </div>
              <!-- 小轮播图 -->
              <div class="left_carouse">
                <el-carousel trigger="click"
                             height="500px"
                             indicator-position="outside"
                             arrow="always"
                             :autoplay="false"
                             initial-index="1"
                             @change="((pre,next,index) => {change(pre, next, 5)})">
                  <el-carousel-item v-for="(item, index) in carouseImgList_3" :key="index">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <p>施工管理</p>
              </div>
            </div>
          </div>
        </div>

        <div class="carouseBox_List">
          <div class="carouseBox_List_content">
            <div class="carouseBox_List__left">
              <div class="left_text">
                <h4>风险监控系统</h4>
                <p>地下管廊因其填埋于地下，其安全隐患不易被发现，系统通过各类传感器（流量，泄漏，水位）等，采集现场数据，对管网的运行风险进行预警报警。</p>
              </div>
              <!-- 小轮播图 -->
              <div class="left_carouse">
                <el-carousel trigger="click"
                             height="500px"
                             indicator-position="outside"
                             arrow="always"
                             :autoplay="false"
                             initial-index="2"
                             @change="((pre,next,index) => {change(pre, next, 6)})">
                  <el-carousel-item v-for="(item, index) in carouseImgList_3" :key="index">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <p>风险监控</p>
              </div>
            </div>
            <div class="carouseBox_List__right" data-aos="fade-left" data-aos-duration="1000">
              <div class="right_carouse">
                <el-carousel
                    trigger="click"
                    height="1000px"
                    indicator-position="outside"
                    arrow="always"
                    :autoplay="false"
                    initial-index="2"
                    ref="carousel6">
                  <el-carousel-item v-for="(item, index) in carouseImgList_4" :key="index">
                    <img v-if="index === 2" :src="item" alt="" style="width: 80%;">
                    <img v-else :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <!--                手机注释-->
                <p class="Mobile">风险监控</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!--    智慧燃气管网-->
    <div v-if="thisSolutionName==='智慧燃气管网'">

      <!--  头部区  -->
      <div class="headerBox" >
        <img :src="baseUrl+'imges/solution/solutionlist_03/solutionBG2_1.png'" alt="">
        <div class="headerBox_content headerBox_BG headerBox_content2" data-aos="fade-up" data-aos-duration="1000">
          <div class="content_Box">
            <h4>智慧燃气管网</h4>
            <h4 class="PC">让燃气管理更安全</h4>
            <h4 class="Mobile">让燃气管理更安全</h4>
            <ul>
              <li>燃气管网压力、流量实时监测</li>
              <li>燃气输配管理“一张网”</li>
              <li>地下综合管网信息交换，共建共享</li>
            </ul>
          </div>
        </div>
      </div>

      <!--  内容区  -->
      <div class="navBox">
        <img :src="baseUrl+'imges/solution/solutionlist_03/solutionBG3_2.png'" alt="">
        <div class="navBox_right">
          <div class="navBox_content">
            <h4 data-aos="fade-up" data-aos-duration="1000">智慧燃气管网</h4>
            <p data-aos="fade-up" data-aos-duration="1000">燃气管网的安全运行关系到社会生产和人民生命财产的安全。燃气传输距离长，使用范围广，燃气管理涉及环节多，从生产、储存到输配监控、运营管理及客户服务。燃气因其可燃性，在管理过程中安全监测尤为重要。</p>
            <p data-aos="fade-up" data-aos-duration="1000">智慧燃气管网解决方案力求打造出燃气生产运营管理“一张图”，燃气输配管理“一张网”。利用大数据、AI、GIS、物联网技术让燃气管理更高效，让政府管理更安心，让民生生活更安全。</p>
            <div class="navBox_content_logo" data-aos="fade-up" data-aos-duration="1000">
              <div>
                <img :src="baseUrl+'imges/solution/solutionlist_03/jianceLogo.svg'" alt="">
                <p>管网实时监测</p>
              </div>
              <div>
                <img :src="baseUrl+'imges/solution/solutionlist_03/shupeiLogo.svg'" alt="">
                <p>输配管理</p>
              </div>
              <div>
                <img :src="baseUrl+'imges/solution/solutionlist_03/solutionLogo3.svg'" alt="">
                <p>管线全生命周期管理</p>
              </div>
              <div>
                <img :src="baseUrl+'imges/solution/solutionlist_03/yingjiLogo.svg'" alt="">
                <p>应急管理</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  特点区  -->
      <div class="featrueBox featrueBoxFire">
        <p data-aos="fade-up" data-aos-duration="1000">核心价值</p>
        <div class="featrueBox_List">
          <div data-aos="fade-up" data-aos-duration="1000">
            <h4>燃气工程可视化</h4>
            <p class="ranqiText">把握燃气工程信息进度节点，实时掌握工程进展情况，施工作业安全监管人员在此基础上对施工作业过程监管和记录，为管理者提供决策依据。</p>
            <img :src="baseUrl+'imges/solution/solutionlist_03/feature7.png'" alt="" class="ranqiImg">
          </div>
          <div data-aos="fade-up" data-aos-duration="1500">
            <h4>科学应急调度</h4>
            <p class="ranqiText">应急事件预案管理、应急流程化、指挥调度科学有效。系统将整个燃气管网所有问题的触发、上报、监测和预警等实现数据流程化管理，避免数据重复或形成数据孤岛。</p>
            <img :src="baseUrl+'imges/solution/solutionlist_03/feature8.png'" alt="" class="ranqiImg">
          </div>
          <div data-aos="fade-up" data-aos-duration="2500">
            <h4>精准巡检</h4>
            <p class="ranqiText">以北斗定位为基础、采用4G/5G技术让巡检工作高效、规范开展。管网隐患及时发现、上报、巡检工作路径可追溯，自动分析风险高发区域、实现绩效量化考核。</p>
            <img :src="baseUrl+'imges/solution/solutionlist_03/feature9.png'" alt="" class="ranqiImg">
          </div>
          <div data-aos="fade-up" data-aos-duration="2500">
            <h4>营业收费便捷</h4>
            <p class="ranqiText">为天然气公司提供关于收费管理、财务管理和经营决策等方面的智能综合化服务，并实现对应业务数据查询，自动生成相关报表。</p>
            <img :src="baseUrl+'imges/solution/solutionlist_03/feature10.png'" alt="" class="ranqiImg">
          </div>
        </div>
      </div>

      <!--  轮播区  -->
      <div class="carouseBox">
        <div class="carouseBox_List">
          <div class="carouseBox_List_content">
            <!--  左侧内容（Mobile）-->
            <div class="carouseBox_List__left Mobile">
              <div class="left_text">
                <h4>三维可视化</h4>
                <p>除了管网二维视图外，系统还具备三维可视化功能。可实现空间分析、水平/垂直测算功能，以及开挖分析、管道剖面分析等。为施工作业提供有效的数据支持，减少开挖风险。</p>
              </div>
              <!-- 小轮播图 -->
              <div class="left_carouse">
                <el-carousel trigger="click"
                             height="500px"
                             indicator-position="outside"
                             arrow="always"
                             :autoplay="false"
                             @change="((pre,next,index) => {change(pre, next, 7)})">
                  <el-carousel-item v-for="(item, index) in carouseImgList_5" :key="index">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <p>爆管分析</p>
              </div>
            </div>
            <!--  右侧内容-->
            <div class="carouseBox_List__right" data-aos="fade-left" data-aos-duration="1000">
              <div class="right_carouse">
                <el-carousel
                    trigger="click"
                    height="1000px"
                    indicator-position="outside"
                    arrow="always"
                    :autoplay="false"
                    ref="carousel7">
                  <el-carousel-item v-for="(item, index) in carouseImgList_6" :key="index">
                    <img v-if="index === 0" :src="item" alt="">
                    <img v-else :src="item" alt="" style="width: 80%;">
                  </el-carousel-item>
                </el-carousel>
                <p class="Mobile">地下管线统计分析</p>
              </div>
            </div>
            <!--  左侧内容（PC）-->
            <div class="carouseBox_List__left PC">
              <div class="left_text">
                <h4>三维可视化</h4>
                <p>除了管网二维视图外，系统还具备三维可视化功能。可实现空间分析、水平/垂直测算功能，以及开挖分析、管道剖面分析等。为施工作业提供有效的数据支持，减少开挖风险。</p>
              </div>
              <!-- 小轮播图 -->
              <div class="left_carouse">
                <el-carousel trigger="click"
                             height="500px"
                             indicator-position="outside"
                             arrow="always"
                             :autoplay="false"
                             @change="((pre,next,index) => {change(pre, next, 7)})">
                  <el-carousel-item v-for="(item, index) in carouseImgList_5" :key="index">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <p>爆管分析</p>
              </div>
            </div>

          </div>
        </div>

        <div class="carouseBox_List" style="background-color: #fff;">
          <div class="carouseBox_List_content">
            <div class="carouseBox_List__left">
              <div class="left_text">
                <h4>巡检管理系统</h4>
<!--                <span>以管廊数据为核心，通过风险因素识别、风险评估、风险等级及分布区域划分、对不同风险设置针对性的感知预警系统</span>-->
                <p>系统具有巡检过程监视，现场故障上报，对巡检任务及巡检人员管理的功能。通过搭配使用移动端，巡检人员可快速，准确上传巡检结果，对安全隐患及时上报。系统自动生成巡检报告，提升巡检工作效用。</p>
              </div>
              <!-- 小轮播图 -->
              <div class="left_carouse">
                <el-carousel trigger="click"
                             height="500px"
                             indicator-position="outside"
                             arrow="always"
                             :autoplay="false"
                             initial-index="1"
                             @change="((pre,next,index) => {change(pre, next, 8)})">
                  <el-carousel-item v-for="(item, index) in carouseImgList_5" :key="index">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <p>巡检路径回放</p>
              </div>
            </div>
            <div class="carouseBox_List__right" data-aos="fade-right" data-aos-duration="1000">
              <div class="right_carouse">
                <el-carousel
                    trigger="click"
                    height="1000px"
                    indicator-position="outside"
                    arrow="always"
                    :autoplay="false"
                    initial-index="1"
                    ref="carousel8">
                  <el-carousel-item v-for="(item, index) in carouseImgList_6" :key="index">
                    <img v-if="index === 0" :src="item" alt="">
                    <img v-else :src="item" alt="" style="width: 80%;">
                  </el-carousel-item>
                </el-carousel>
<!--                手机注释-->
                <p class="Mobile">巡检路径回放</p>
              </div>
            </div>
          </div>
        </div>

        <div class="carouseBox_List">
          <div class="carouseBox_List_content">
            <!--  左侧内容（Mobile）-->
            <div class="carouseBox_List__left Mobile">
              <div class="left_text">
                <h4>工程管理系统</h4>
                <p>管网工程是一项涉及面广，资料多，管理难度大的工作。系统可协助管理工程的每个关键点，监督工程进度、管控工程人员，以及施工文档（合同，图纸）等管理。同时，完成工程数据分析，对工程作业进行多维度统计分析。</p>
              </div>
              <!-- 小轮播图 -->
              <div class="left_carouse">
                <el-carousel trigger="click"
                             height="500px"
                             indicator-position="outside"
                             arrow="always"
                             :autoplay="false"
                             initial-index="2"
                             @change="((pre,next,index) => {change(pre, next, 9)})">
                  <el-carousel-item v-for="(item, index) in carouseImgList_5" :key="index">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <p>工程进度统计</p>
              </div>
            </div>
            <!--  右侧内容-->
            <div class="carouseBox_List__right" data-aos="fade-left" data-aos-duration="1000">
              <div class="right_carouse">
                <el-carousel
                    trigger="click"
                    height="1000px"
                    indicator-position="outside"
                    arrow="always"
                    :autoplay="false"
                    initial-index="2"
                    ref="carousel9">
                  <el-carousel-item v-for="(item, index) in carouseImgList_6" :key="index">
                    <img v-if="index === 0" :src="item" alt="">
                    <img v-else :src="item" alt="" style="width: 80%;">
                  </el-carousel-item>
                </el-carousel>
                <!--                手机注释-->
                <p class="Mobile">工程进度统计</p>
              </div>
            </div>
            <!--  左侧内容（PC）-->
            <div class="carouseBox_List__left PC">
              <div class="left_text">
                <h4>工程管理系统</h4>
                <p>管网工程是一项涉及面广，资料多，管理难度大的工作。系统可协助管理工程的每个关键点，监督工程进度、管控工程人员，以及施工文档（合同，图纸）等管理。同时，完成工程数据分析，对工程作业进行多维度统计分析。</p>
              </div>
              <!-- 小轮播图 -->
              <div class="left_carouse">
                <el-carousel trigger="click"
                             height="500px"
                             indicator-position="outside"
                             arrow="always"
                             :autoplay="false"
                             initial-index="2"
                             @change="((pre,next,index) => {change(pre, next, 9)})">
                  <el-carousel-item v-for="(item, index) in carouseImgList_5" :key="index">
                    <img :src="item" alt="">
                  </el-carousel-item>
                </el-carousel>
                <p>工程进度统计</p>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

    <!--  表单区  -->
    <div class="form">
      <p data-aos="fade-up" data-aos-duration="1000">联系我们</p>
      <div class="form_Box PC">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <el-row :gutter="29">
            <el-col :span="12">
              <el-form-item  prop="name">
                <el-input v-model="ruleForm.name" placeholder="姓名 *"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  prop="phone">
                <el-input v-model="ruleForm.phone" placeholder="电话 *" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="29">
            <el-col :span="12" >
              <el-form-item  prop="email">
                <el-input v-model="ruleForm.email" placeholder="邮箱 *"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  prop="company">
                <el-input v-model="ruleForm.company" placeholder="公司 *"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="29">
            <el-col :span="12">
              <el-form-item prop="identity">
                <el-input v-model="ruleForm.identity" placeholder="您的职位 *"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" >
              <el-form-item prop="province">
                <el-select v-model="ruleForm.province" placeholder="所在省份" style="width: 100%" disabled>
                  <el-option :value="province"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
<!--          <el-row :gutter="29">-->

<!--            <el-col :span="12">-->
<!--              <el-form-item prop="city">-->
<!--                <el-select v-model="ruleForm.city" placeholder="所在城市" style="width: 100%" disabled>-->
<!--                  <el-option :value="city"></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->
          <el-row>
            <el-col :span="24" >
              <el-form-item  prop="remark">
                <el-input type="textarea"  placeholder="备注" v-model="ruleForm.remark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
<!--            <el-button @click="submitForm('ruleForm')" class="submit">提交</el-button>-->
            <el-button @click="submitForm('ruleForm')" class="submit">提交</el-button>
<!--                        <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          </el-form-item>
        </el-form>
      </div>
      <div class="form_Box Mobile">
        <el-form :model="ruleForm1" :rules="rules" ref="ruleForm1" class="demo-ruleForm">
          <el-row :gutter="29">
            <el-col :span="24">
              <el-form-item  prop="name">
                <el-input v-model="ruleForm1.name" placeholder="姓名 *"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item  prop="phone">
                <el-input v-model="ruleForm1.phone" placeholder="电话 *" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="29">
            <el-col :span="24" >
              <el-form-item  prop="email">
                <el-input v-model="ruleForm1.email" placeholder="邮箱 *"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item  prop="company">
                <el-input v-model="ruleForm1.company" placeholder="公司 *"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="29">
            <el-col :span="24">
              <el-form-item prop="identity">
                <el-input v-model="ruleForm.identity" placeholder="您的职位 *"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="29">
            <el-col :span="24" >
              <el-form-item prop="province">
                <el-select v-model="ruleForm1.province" placeholder="所在省份" style="width: 100%" disabled>
                  <el-option :value="province"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
<!--          <el-row>-->
<!--            <el-col :span="24">-->
<!--              <el-form-item prop="city">-->
<!--                <el-select v-model="ruleForm1.city" placeholder="所在城市" style="width: 100%" disabled>-->
<!--                  <el-option :value="city"></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--          </el-row>-->

          <el-form-item>
            <el-button @click="submitForm('ruleForm1')" class="submit">提交</el-button>
            <!--                        <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          </el-form-item>
        </el-form>
      </div>

    </div>

    <Footer
        @thisPageType="thisPageType"
    ></Footer>

  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "Solution",

  data() {
    //配置自定义校验规则
    let validatePhone = (rule,value,callback)=> {
          if (!value) {
            callback(new Error('请输入电话号码！'));
          } else {
            return callback()
          }
          //使用正则表达式进行验证手机号码
          if (!/^1[3456789]\d{9}$/.test(value)) {
            callback(new Error('请输入正确的电话号码！'));
          } else {
            return callback()
          }
        }
    let isName = (rule, value, callback) => {
      if(!value){
        callback(new Error('请输入姓名！'));
      } else {
        return callback()
      }
      if(/^[u4E00-u9FA5]+$/.test(value)){
        callback(new Error('姓名格式不正确！'));
      } else {
        return callback()
      }
    }

    return {
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      //当前解决方案名称
      thisSolutionName: this.$route.params.name||"智慧化工管廊",

      carouseImgList_1: ['http://www.cd-rst.top:18000/static/imges/solution/solutionlist_01/carouselOne_01.png',
        'http://www.cd-rst.top:18000/static/imges/solution/solutionlist_01/carouselTwo_01.png',
        'http://www.cd-rst.top:18000/static/imges/solution/solutionlist_01/carouselThree_01.png'],
      carouseImgList_2: ['http://www.cd-rst.top:18000/static/imges/solution/solutionlist_01/carouselOne_11.png',
        'http://www.cd-rst.top:18000/static/imges/solution/solutionlist_01/carouselTwo_11.png',
        'http://www.cd-rst.top:18000/static/imges/solution/solutionlist_01/carouselThree_11.png'],
      carouseImgList_3: ['http://www.cd-rst.top:18000/static/imges/solution/solutionlist_02/carouselOne_01.png',
        'http://www.cd-rst.top:18000/static/imges/solution/solutionlist_02/carouselTwo_01.png',
        'http://www.cd-rst.top:18000/static/imges/solution/solutionlist_02/carouselThree_01.png'],
      carouseImgList_4: ['http://www.cd-rst.top:18000/static/imges/solution/solutionlist_02/carouselOne_11.png',
        'http://www.cd-rst.top:18000/static/imges/solution/solutionlist_02/carouselTwo_11.png',
        'http://www.cd-rst.top:18000/static/imges/solution/solutionlist_02/carouselThree_11.png'],
      carouseImgList_5: ['http://www.cd-rst.top:18000/static/imges/solution/solutionlist_03/carouselOne_01.png',
        'http://www.cd-rst.top:18000/static/imges/solution/solutionlist_03/carouselTwo_01.png',
        'http://www.cd-rst.top:18000/static/imges/solution/solutionlist_03/carouselThree_01.png'],
      carouseImgList_6: ['http://www.cd-rst.top:18000/static/imges/solution/solutionlist_03/carouselOne_11.png',
        'http://www.cd-rst.top:18000/static/imges/solution/solutionlist_03/carouselTwo_11.png',
        'http://www.cd-rst.top:18000/static/imges/solution/solutionlist_03/carouselThree_11.png'],

      ruleForm: {
        name: '',
        phone: '',
        email: '',
        company: '',
        province: '',
        city: '',
        identity: '',
        remark: '',
      },
      ruleForm1: {
        name: '',
        phone: '',
        email: '',
        company: '',
        province: '',
        city: '',
        identity: '',
      },
      rules: {
        name: [
          { validator: isName, trigger: 'blur' }
        ],
        phone: [
          { validator: validatePhone, trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱地址！', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址！', trigger: ['blur', 'change'] }
        ],
        company: [
          { required: true, message: '请输入公司名！', trigger: 'blur' }
        ],
        identity: [
          { required: true, message: '请输入职位信息！', trigger: 'blur' }
        ],
      },
      province: '',
      city: '',
    }
  },
  components: {
    Header,
    Footer
  },

  mounted(options) {
    this.$refs.header.openSolution()
    this.ready()
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid, obj) => {
        if (valid) {
          this.$message.success("提交成功！")
          this.$refs[formName].resetFields();
        } else {
          this.$message.error("请正确填写表单！")
          return false;
        }

      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      // this.ruleForm.remark = ''
    },
    //轮播图切换事件
    change(pre,next,index){
      this.setActiveItem(pre,index)
    },
    //大轮播图联动
    setActiveItem(pre,index){
      //eval()函数是将括号里的参数作为代码来执行
      let refElement = eval("this.$refs.carousel" + index)
      refElement.setActiveItem(pre)
    },
    //右侧导航点击事件
    thisPageType(e) {
      this.thisSolutionName = e;
    },
    // 获取本机ip
    ready:function(){
      let area = returnCitySN.cname.split('省')
      this.province = area[0] + '省'
      this.city = area[1]
      this.ruleForm.province = returnCitySN.cname
      this.ruleForm.city = area[1]
      this.ruleForm1.province = returnCitySN.cname
      this.ruleForm1.city = area[1]
    },
  }
}
</script>

<style lang="less" scoped>
#solution {
  background: #FFFFFF;
  width: 100%;
  min-width: 1250px;
  font-family: Regular;
  .PC{
    display: block;
  }
  .MobileBG{
    display: none;
  }
  .Mobile{
    display: none;
  }
  //头部区
  .headerBox {
    position: relative;
    width: 100%;
    height: 1080px;
    img{
      width: 100%;
      height: 1080px;
    }
    .headerBox_BG{
      position: absolute;
      top: 256px;
      left: 10.26vw;
      background: url("http://www.cd-rst.top:18000/static/imges/solution/solutionlist_02/headerBox_BG.png") no-repeat;
      background-size: 705px 428px;
    }
    .headerBox_content{
      position: absolute;
      top: 256px;
      left: 10.26vw;
      width: 1075px;
      height: 428px;
      .content_Box{
        margin-top: 67px;
        margin-left: 100px;
        text-align: left;
        h4{
          font-family: Medium;
          font-size: 50px;
          font-weight: 500;
          line-height: 80px;
          color: #FFFFFF;
        }
        ul{
          margin-top: 17px;
          li{
            background: url("http://www.cd-rst.top:18000/static/imges/solution/solutionlist_02/li_Logo.png") no-repeat 3px;
            padding-left: 26px;
            margin-bottom: 17px;
            font-family: Medium;
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  //内容区
  .navBox{
    display: flex;
    justify-content: left;
    width: 100%;
    height: 800px;
    img{
      //width: 1013px;
      width: 52vw;
      height: 800px;
    }
    .navBox_right{
      width: 908px;
      background-color: #0564AF;
      .navBox_content{
        position: relative;
        margin-top: 176px;
        margin-left: 105px;
        width: 645px;
        text-align: left;
        color: #FFFFFF;
        font-family: Regular;
        letter-spacing: 0.05em;
        h4{
          //width: 190px;
          width: 100%;
          height: 47px;
          font-weight: normal;
          font-size: 30px;
          margin-bottom: 54px;
        }
        h4:after{
          /* 必须设置 content 属性才能生效 */
          content: '';
          /* border 宽度 */
          width: 59px;
          /* border 高度 */
          height: 4px;
          background-color: #F2F3FE;
          /* border 位置 absolute(绝对定位) */
          position: absolute;
          left: 0px;
          top: 50px;
          /* 自动内减 */
          box-sizing: border-box;
        }
        p{
          width: 645px;
          font-size: 16px;
          font-weight: normal;
          line-height: 28px;
          margin-bottom: 30px;
        }
        .navBox_content_logo{
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 96px;
          margin-top: 76px;
          div{
            width: 128px;
            height: 99px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            color: #F2F3FE;
            font-family: Regular;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0.05em;
            img{
              width: 51px !important;
              height: 51px !important;
            }
            p{
              margin-top: 18px;
              width: 100%;
              height: 33px;
            }
          }
        }
      }
    }
  }

  //特点区
  .featrueBox{
    position: relative;
    width: 100%;
    height: 800px;
    >p{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 100px;
      width: 1142px;
      height: 42px;
      font-family: Regular;
      font-size: 30px;
      font-weight: normal;
      letter-spacing: 0.05em;
      color: #000000;
    }
    >p:after{
      /* 必须设置 content 属性才能生效 */
      content: '';
      /* border 宽度 */
      width: 56px;
      /* border 高度 */
      height: 4px;
      background-color: #0564AF;
      /* border 位置 absolute(绝对定位) */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      /* 自动内减 */
      box-sizing: border-box;
    }
    .featrueBox_List{
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 250px;
      left: 50%;
      transform: translateX(-50%);
      width: 1561px;
      height: 413px;
      div{
        width: 420px;
        height: 413px;
        h4{
          font-family: Medium;
          font-size: 18px;
          font-weight: 500;
          color: #3D3D3D;
          margin-bottom: 13px;
        }
        p{
          width: 370px;
          height: 68px;
          font-family: Regular;
          font-size: 12px;
          font-weight: normal;
          line-height: 20px;
          color: #454545;
          margin: 0 auto 57px;
        }
        .ranqiText{
          width: 329px;
        }
        .ranqiImg{
          width: 360px;
          height: 260px;
        }
        img{
          width: 420px;
          height: 253px;
          transition: all .6s;
        }
        .imgheight{
          height: 292px;
        }

        img:hover{
          transform: scale(1.1);
        }
      }
    }
  }

  //轮播区
  .carouseBox{
    display: flex;
    flex-direction: column;
    justify-content: left;
    background: #F4F4F4;
    width: 100%;
    height: 2400px;
    .carouseBox_List{
      width: 100%;
      height: 800px;
      .carouseBox_List_content{
        display: flex;
        justify-content: space-between;
        width: 1456px;
        height: 570px;
        margin: 110px auto 0;
        .PC{display: block}
        .Mobile{display: none}
        .carouseBox_List__left{
          width: 472px;
          .left_text{
            position: relative;
            margin-top: 20px;
            margin-left: 36px;
            font-family: Regular;
            letter-spacing: 0.05em;
            text-align: left;
            h4{
              position: relative;
              font-size: 30px;
              font-weight: normal;
              color: #000000;
              margin-bottom: 40px;
              span{
                display: block;
                position: absolute;
                font-size: 15px !important;
                top: 14px;
                left: 216px;
              }
            }
            h4:after{
              /* 必须设置 content 属性才能生效 */
              content: '';
              /* border 宽度 */
              width: 56px;
              /* border 高度 */
              height: 4px;
              background-color: #0564AF;
              /* border 位置 absolute(绝对定位) */
              position: absolute;
              left: 0;
              top: 50px;
              /* 自动内减 */
              box-sizing: border-box;
            }
            p{
              width: 472px;
              height: 173px;
              font-size: 14px;
              font-weight: normal;
              line-height: 26px;
              color: #3D3D3D;
            }
            span{
              display: block;
              width: 472px;
              font-size: 14px;
              font-weight: normal;
              line-height: 26px;
              color: #3D3D3D;
              margin-bottom: 30px;
            }
          }
          .left_carouse{
            position: relative;
            width: 342px;
            height: 224px;
            img{
              width: 278px;
              height: 151px;
            }
            p{
              position: absolute;
              left: 30px;
              bottom: 30px;
              text-align: left;
              //margin-left: 30px;
              //margin-top: 10px;
            }
          }
          //element轮播图进度条位置
          .left_carouse /deep/ .el-carousel__indicators--outside {
            //visibility: hidden;  //轮播效果暂时隐藏
display: none;
            text-align: left;
            margin-left: 28px;
            margin-top: 40px;
          }
          //进度条颜色
          .left_carouse /deep/ .el-carousel__indicators--outside button {
            background-color: #0564AF;
          }
          //进度条宽高
          .left_carouse /deep/ .el-carousel__button {
            width: 51px;
            height: 4px;
          }
          //箭头样式
          .left_carouse /deep/ .el-carousel__arrow{
            visibility: hidden;  //轮播效果暂时隐藏

            width: 24px;
            height: 24px;
            font-size: 24px;
            border-radius: 0;
            background: rgba(0,0,0,0) !important;
            color: #D8D8D8;
            &:hover{
              color: #0564AF !important;
              background: rgba(0,0,0,0) !important;
            }
          }
          //左箭头位置
          .left_carouse /deep/ .el-carousel__arrow--left {
            color: black;
            left: 0;
          }
          //右箭头位置
          .left_carouse /deep/ .el-carousel__arrow--right {
            color: black;
            right: 0;
          }
        }
        .carouseBox_List__right{
          width: 877px;
          height: 511px;
          .right_carouse{
            position: relative;
            img{
              width: 877px;
              height: 511px;
            }
            .phoneBG{
              display: block;
              position: absolute;
              bottom: 0;
              left: 88px;
              width: 164px;
              height: 248px;
              z-index: 10;
            }
          }
          //隐藏进度条
          .right_carouse /deep/ .el-carousel__indicators--outside {
            display: none !important;
          }
          //隐藏箭头
          .right_carouse /deep/ .el-carousel__arrow{
            display: none !important;
          }
        }
      }
    }
  }

  //表单区
  .form{
    width: 100%;
    >p{
      position: relative;
      margin: 70px auto 0;
      width: 1142px;
      height: 42px;
      font-family: Regular;
      font-size: 30px;
      font-weight: normal;
      letter-spacing: 0.05em;
      color: #000000;
    }
    >p:after{
      /* 必须设置 content 属性才能生效 */
      content: '';
      /* border 宽度 */
      width: 56px;
      /* border 高度 */
      height: 4px;
      background-color: #0564AF;
      /* border 位置 absolute(绝对定位) */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
      /* 自动内减 */
      box-sizing: border-box;
    }
    .form_Box{
      margin: 117px auto;
      width: 955px;
      height: 565px;
      margin-bottom: 77px;
      .submit {
        --bg-color: #0564AF;
        --font-color: #fff;

        width: 149px;
        height: 42px;
        box-sizing: border-box;
        border: 1px solid #0564AF;
        border-radius: 0;
        margin-top: 57px;

        line-height: 42px;
        padding: 0;
        font-family: Regular;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0.05em;
        color: #0564AF;

        transition: all 200ms ease-out;
        /* 以下为核心代码 */
        background-size: 200% 100%;
        background-position: right bottom;
        background-image: linear-gradient(to left, var(--font-color) 50%, var(--bg-color) 50%);
        //鼠标经过按钮
        &:hover {
          cursor: pointer;
          background-position: left bottom;
          color: #FFFFFF;
        }
      }
    }
  }
}

//媒体查询适应手机端
@media screen and (min-width: 360px) and (max-width: 750px) {
  html {
    font-size: 140px / 10;
  }
  #solution{
    background: #FFFFFF;
    width: 100%;
    height: 100%;

    //头部区
    .headerBox{
      position: relative;
      width: 100%;
      height: 590px;
      margin-top: 50px;
      .PC{
        display: none;
      }
      .Mobile{
        margin-top: 20px;
        display: block;
      }
      .MobileBG, img{
        display: block;
        width: 100%;
        height: 100%;
      }
      .headerBox_BG{
        position: absolute;
        top: 178px;
        left: 50%;
        transform: translateX(-50%);
        background-size: 100% 100%;
      }
      .headerBox_content2{
        height: 260px !important;
      }
      .headerBox_content{
        position: absolute;
        top: 178px;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        height: 300px;
        .content_Box{
          margin: 22px;
          text-align: center !important;
          font-family: Medium;
          font-weight: 500;
          letter-spacing: 0em;
          color: #FFFFFF;
          h4{
            width: 100%;
            font-size: 30px;
            line-height: 41px;
          }
          ul{
            margin: 20px auto 0;
            li{
              width: 100%;
              display: block;
              background: 0;
              margin-bottom: 10px;
              height: 24px;
              font-size: 14px;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }
      }
    }

    //内容区
    .navBox {
      display: flex;
      flex-direction: column;
      justify-content: left;
      width: 100%;
      //height: 833px;
      height: 100%;
      img{
        width: 100%;
        height: 293px;
      }
      .right_hight{
        //height: 560px !important;
      }
      .navBox_right{
        width: 100%;
        //height: 530px;
        height: 100%;
        background-color: #0564AF;
        .navBox_content{
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          top: 43px;
          width: 90%;
          height: 100%;
          margin: 0;
          color: #FFFFFF;
          font-family: Regular;
          letter-spacing: 0.05em;
          h4{
            text-align: center;
            width: 100%;
            height: 20px;
            font-size: 18px;
            font-weight: normal;
            line-height: 10px;
            margin-bottom: 31px;
          }
          h4:after{
            /* 必须设置 content 属性才能生效 */
            content: '';
            /* border 宽度 */
            width: 39px;
            /* border 高度 */
            height: 3px;
            background-color: #F2F3FE;
            /* border 位置 absolute(绝对定位) */
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 30px;
            /* 自动内减 */
            box-sizing: border-box;
          }
          p{
            text-align: left;
            font-family: Regular;
            width: 100%;
            //height: 110px;
            font-size: 12px;
            font-weight: normal;
            color: #F2F3FE;
            line-height: 20px;
            margin-bottom: 20px;
          }
          .navBox_content_logo{
            display: flex;
            justify-content: space-between;
            //width: 280px;
            width: 100%;
            //height: 84px;
            height: 100%;
            margin-top: 50px;
            //margin: 28px 0 60px;
            div{
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              //width: 117px;
              //width: 42%;
              width: 25%;
              height: 100%;
              align-items: center;
              color: #F2F3FE;
              font-family: Regular;
              font-size: 16px;
              font-weight: normal;
              letter-spacing: 0.05em;
              img{
                width: 36px !important;
                height: 36px !important;
              }
              p{
                //height: 100%;
                text-align: center;
                margin-top: 18px;
                width: 100%;
                height: 120px;
              }
            }
          }
        }
      }
    }

    //特点区
    .featrueBoxFire{
      height: 1730px !important;
    }
    .featrueBox {
      position: relative;
      width: 100%;
      height: 1300px;
      >p{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 100px;
        width: 100%;
        font-family: Regular;
        height: 29px;
        font-size: 20px;
        font-weight: normal;
        letter-spacing: 0.05em;
        color: #454545;
      }
      >p:after{
        /* 必须设置 content 属性才能生效 */
        content: '';
        /* border 宽度 */
        width: 31px;
        /* border 高度 */
        height: 2px;
        background-color: #0564AF;
        /* border 位置 absolute(绝对定位) */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 40px;
        /* 自动内减 */
        box-sizing: border-box;
      }
      .featrueBox_List{
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translateX(-50%);
        width: 89%;
        height: 100%;
        div{
          width: 100%;
          height: 302px;
          margin-bottom: 90px;
          h4{
            font-family: Medium;
            font-size: 18px;
            font-weight: 500;
            color: #3D3D3D;
            margin-bottom: 13px;
          }
          p{
            width: 100% !important;
            height: 68px !important;
            font-family: Regular;
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            color: #454545;
            text-align: left;
            margin: 0 auto 30px;
          }
          .ranqiText{
            width: 329px;
          }
          .ranqiImg{
            width: 280px;
            height: 170px;
          }
          img{
            //width: 280px;
            width: 76% !important;
            height: 169px !important;
            transition: all .6s;
          }
          img:hover{
            transform: scale(1.1);
          }
        }
      }
    }

    //轮播区
    .carouseBox {
      display: flex;
      flex-direction: column;
      justify-content: left;
      background: #FFFFFF;
      width: 100%;
      height: 100%;
      .carouseBox_List{
        width: 100%;
        height: 100%;
        margin-bottom: 60px;
        .carouseBox_List_content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 90%;
          height: 100%;
          margin: 0 auto;
          overflow: hidden;
          .Mobile{display: block}
          .PC{display: none}
          .carouseBox_List__left {
            width: 100%;
            //height: 299px;
            height: 100%;
            .left_text {
              position: relative;
              margin: 40px auto 0;
              font-family: Regular;
              letter-spacing: 0.05em;
              text-align: left;
              margin-bottom: 40px;
              h4 {
                text-align: center;
                font-size: 20px;
                font-weight: normal;
                margin-bottom: 32px;
                color: #454545;
                span{
                  display: none;
                }
              }
              h4:after {
                /* 必须设置 content 属性才能生效 */
                content: '';
                /* border 宽度 */
                width: 31px;
                /* border 高度 */
                height: 2px;
                background-color: #0564AF;
                /* border 位置 absolute(绝对定位) */
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 40px;
                /* 自动内减 */
                box-sizing: border-box;
              }
              p {
                width: 100%;
                //height: 173px;
                height: 100%;
                font-size: 12px;
                font-weight: normal;
                line-height: 26px;
                color: #3D3D3D;
              }
              span {
                display: block;
                width: 100%;
                font-size: 12px;
                font-weight: normal;
                line-height: 26px;
                color: #3D3D3D;
                margin-bottom: 15px;
              }
            }
            .left_carouse {
              display: none !important;
            }
          }

          .carouseBox_List__right {
            position: relative;
            width: 100%;
            height: 211px;
            .right_carouse {
              position: relative;
              img {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                //width: 280px !important;
                width: 76% !important;
                height: 163.75px !important;
                //margin-left: 5%;
              }
              .phoneBG {
                display: block;
                position: absolute;
                bottom: 0;
                left: 88px;
                width: 164px;
                height: 248px;
                z-index: 10;
              }
              .Mobile{
                display: block;
                position: absolute;
                top: 180px;
                left: 50%;
                transform: translateX(-50%);

                font-family: Regular;
                font-size: 16px;
                font-weight: normal;
                letter-spacing: 0em;
                color: #3D3D3D;
              }
            }
            //隐藏进度条
            .right_carouse /deep/ .el-carousel__indicators--outside {
              display: none !important;
            }
            //显示箭头
            .right_carouse /deep/ .el-carousel__arrow{
              //手机轮播效果也暂时隐藏
              //display: block !important;

              width: 12px;
              height: 24px;
              font-size: 30px !important;
              border-radius: 0;
              color: #0564AF;
              background: 0;
            }
            //左箭头位置
            .right_carouse /deep/ .el-carousel__arrow--left {
              //left: -7px;
              left: 0;
              top: 75px;
            }
            //右箭头位置
            .right_carouse /deep/ .el-carousel__arrow--right {
              //right: 9px;
              top: 79px;
            }
          }
        }
      }
    }

    //表单区
    .form {
      position: relative;
      width: 100%;
      height: 750px;
      >p{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 29px;
        font-family: Regular;
        font-size: 20px;
        font-weight: normal;
        letter-spacing: 0.05em;
        color: #454545;
      }
      >p:after{
        /* 必须设置 content 属性才能生效 */
        content: '';
        /* border 宽度 */
        width: 31px;
        /* border 高度 */
        height: 2px;
        background-color: #0564AF;
        /* border 位置 absolute(绝对定位) */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 50px;
        /* 自动内减 */
        box-sizing: border-box;
      }
      .PC{display: none}
      .Mobile{display: block}
      .form_Box{
        position: absolute;
        top: 107px;
        left: 50%;
        transform: translateX(-50%);
        //width: 312px;
        width: 87%;
        height: 45px;
        .submit{
          width: 149px;
          height: 42px;
          box-sizing: border-box;
          border: 1px solid #0564AF;
          border-radius: 0;
          margin-top: 55px;

          line-height: 42px;
          padding: 0;
          font-family: Regular;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0.05em;
          color: #0564AF;
          }
      }
    }
  }
}
</style>

<style lang="less">
.el-textarea>.el-textarea__inner{
  min-height: 121px !important;
}
</style>
